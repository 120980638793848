import React, { useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useQuery } from "../hooks/useQuery";
import { useAuth } from "./Authentication";
import { useAuth0 } from "@auth0/auth0-react";
import * as api from "../api";

import * as Styled from "./Actions.styles";

function DummyButtons() {
  const auth = useAuth();
  const [msg, setMsg] = useState(false);
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <hr />
      <Styled.Title onClick={() => setShow(!show)}>
        <span style={{ flex: 1 }}>Export</span>
        <i className="fas fa-bars"></i>
      </Styled.Title>
      <Styled.Toggle show={show}>
        <Styled.Button
          onClick={() => {
            auth.mixpanel.track("Export to Markdown", {});
            setMsg(true);
          }}
        >
          <i className="fab fa-markdown fa-fw" /> Export to Markdown
        </Styled.Button>
        <Styled.Button
          onClick={() => {
            auth.mixpanel.track("Export to Script", {});
            setMsg(true);
          }}
        >
          <i className="far fa-file-code fa-fw" /> Export to Bash Script
        </Styled.Button>
        {msg && <Styled.Message>Coming Soon :)</Styled.Message>}
      </Styled.Toggle>
    </React.Fragment>
  );
}

export default function Actions({ workflow, onChange }) {
  const auth = useAuth();
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();
  const params = useParams();
  const query = useQuery();
  const [show, setShow] = useState(false);
  const isMyWorkflow = auth.isAuthenticated && auth.user.nickname === workflow.username;

  async function togglePrivacy() {
    const newVisibility = workflow.visibility === "public" ? "private" : "public";
    await api.changeVisibility(params.username, params.name, newVisibility);
    if (newVisibility === "private") {
      history.push("?a=" + workflow.uuid);
    } else {
      history.push(history.location.pathname);
      await onChange();
    }
  }

  async function deleteWorkflow() {
    await api.deleteWorkflow(params.username, params.name);
    history.push(`/${params.username}`);
  }

  if (!isMyWorkflow) {
    return (
      <Styled.Container>
        <Styled.Action onClick={() => loginWithRedirect()}>Login to edit workflow</Styled.Action>
        <DummyButtons />
      </Styled.Container>
    );
  }

  return (
    <Styled.Container>
      <Styled.Title onClick={() => setShow(!show)}>
        <span style={{ flex: 1 }}>Actions</span>
        <i className="fas fa-bars"></i>
      </Styled.Title>
      <Styled.Toggle show={show}>
        {workflow.visibility === "public" ? (
          <Styled.Action onClick={togglePrivacy}>
            <i className="far fa-eye-slash fa-fw" /> Change to Private
          </Styled.Action>
        ) : (
          <Styled.Action onClick={togglePrivacy}>
            <i className="far fa-eye fa-fw" /> Change to Public
          </Styled.Action>
        )}
        <Styled.Action onClick={deleteWorkflow}>
          <i className="far fa-trash fa-fw" /> Delete
        </Styled.Action>
      </Styled.Toggle>
      <DummyButtons />
    </Styled.Container>
  );
}
