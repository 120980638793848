import { useState, useEffect, useCallback } from "react";

export default function useGetResult(getRequest, dependencies = [], initialValue = null, errorMessage = null) {
  const [state, setState] = useState({
    error: null,
    result: initialValue,
    isLoading: true
  });

  const reload = useCallback(() => {
    setState({ isLoading: true, result: initialValue, error: null });
    getRequest().then(result => {
      setState({ isLoading: false, result: result.data, error: null });
    }).catch(err => {
      setState({ isLoading: false, result: initialValue, error: err });
    })

  }, dependencies);

  useEffect(() => {
    return reload();
  }, [reload]);

  return [state.result, state.isLoading, state.error, reload];
}
