import React from "react";
import { Link } from "react-router-dom";
import * as Styled from "./UserInfo.styles";
import * as github from "../api/github";
import useGetResult from "../hooks/useGetResult";

export default function UserInfo({ username }) {
  const [user, isLoading] = useGetResult(async () => {
    return github.userInfo(username);
  }, [username]);

  if (isLoading) {
    return <Styled.Container></Styled.Container>;
  }

  return (
    <Styled.Container>
      <Styled.Segment>
        <Styled.Avatar src={user.avatar_url} />
      </Styled.Segment>
      <Styled.Info>
        {user.name && <Styled.Name>{user.name}</Styled.Name>}
        <Styled.Username>
          <Link to={`/${username}`}>{username}</Link>
        </Styled.Username>
        <Styled.Metadata>
          <a href={`https://github.com/${username}`} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github fa-fw" /> GitHub Profile
          </a>
        </Styled.Metadata>
        {/* <Styled.Metadata>
          <i className="far fa-scroll" />
          <b>?</b> workflows
        </Styled.Metadata> */}
      </Styled.Info>
    </Styled.Container>
  );
}
