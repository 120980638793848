import React from "react";
import { Link } from "react-router-dom";
import { format } from "timeago.js";

import * as Styled from "./WorkflowList.styles";

export default function WorkflowList({ workflows, editMode = false }) {
  function getLink(workflow) {
    if (workflow.visibility === "public") {
      return `/${workflow.username}/${workflow.name}`;
    } else {
      return `/${workflow.username}/${workflow.name}?a=${workflow.uuid}`;
    }
  }

  return (
    <Styled.List>
      {workflows.map(workflow => (
        <Styled.Item key={workflow._id}>
          <Styled.Title>
            <Link to={getLink(workflow)}>
              <b>{workflow.name}</b>
            </Link>
          </Styled.Title>
          <Styled.Date>{format(workflow.last_modified)}</Styled.Date>
          {!editMode && (
            <Styled.Username>
              (<Link to={`/${workflow.username}`}>{workflow.username}</Link>)
            </Styled.Username>
          )}
        </Styled.Item>
      ))}
    </Styled.List>
  );
}
