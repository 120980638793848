import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import mixpanelLib from "mixpanel-browser";


export const AuthContext = React.createContext();

const ContextProvider = ({ children }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);
  const mixpanel = mixpanelLib.init("9712704fa460979be4354b2fea7e1547", {}, "WebApp");

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `cliapi_publish`,
          scope: "cliapi_publish"
        });

        axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
        setToken(token);
      } catch (e) {}
    };

    getUserMetadata();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (isAuthenticated && user) {
    mixpanel.identify(user.nickname);
    mixpanel.people.set({
      USER_ID: user.nickname,
      '$name': user.nickname
    });

  }

  const context = {
    user,
    isAuthenticated,
    mixpanel
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default ContextProvider;
