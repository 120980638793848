import _ from "lodash";
import copy from "copy-to-clipboard";
import Output from "./Output";

import * as Styled from "./styles";

function Command({ entry }) {
  function copyContent(content) {
    copy(content);
  }

  // If Command is empty
  if (_.isEmpty(entry.command)) {
    return <></>;
  }

  return (
    <Styled.CommandContainer>
      <p>Run the following command:</p>
      <code>
        {entry.command}
        <Styled.CopyButton onClick={() => copyContent(entry.command)}>
          <i className="fal fa-copy"></i>
        </Styled.CopyButton>
      </code>
      <Output entry={entry} />
    </Styled.CommandContainer>
  );
}

export default Command;