import styled, { css } from "styled-components";

export const Container = styled.div`
  user-select: none;
`;

export const Toggle = styled.div`
  @media only screen and (max-width: 600px) {
    margin-top: 0.5rem;
  }
  ${props =>
    !props.show &&
    css`
      @media only screen and (max-width: 600px) {
        display: none;
      }
    `};
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;

  & i {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 0;
    padding: 0.5rem;
    background: #eaeaea;
    & i {
      display: block;
    }
  }
`;

export const Action = styled.div`
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 0.9rem;

  cursor: pointer;
  color: #000000d0;
  text-decoration: none;

  & a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    color: #0088ff;
  }
`;

export const Button = styled(Action)`
  padding: 10px;
  border-radius: 5px;
  border: 1px #0087bd solid;
`;

export const Message = styled.div`
  color: #bf0c0c;
  font-weight: bold;
  text-align: center;
`;
