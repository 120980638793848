import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px #d2d2d2 solid;

  @media only screen and (max-width: 600px) {
    flex-direction: row;
    font-size: 0.7rem;
    padding-bottom: 0rem;
  }
`;

export const Segment = styled.div``;
export const Info = styled.div`
  & a {
    color: inherit;
    text-decoration: inherit;
    &:hover {
      color: #0088ff;
    }
  }
`;

export const Avatar = styled.img`
  max-width: 200px;
  margin-bottom: 1em;

  @media only screen and (max-width: 600px) {
    max-width: 80px;
    margin-right: 20px;
  }
`;

export const Name = styled.div`
  font-size: 1.8em;
  font-weight: bold;
`;

export const Username = styled.div`
  font-size: 1.4em;
  margin-top: 2px;
  /* margin-bottom: 8px; */

`;

export const Metadata = styled.div`
  font-size: 0.95em;
  margin-top: 5px;

  & i {
    color: #353535;
    font-size: 0.9em;
    display: inline-block;
    margin-right: 2px;
  }
`;
