import React from "react";
import { useParams } from "react-router-dom";
import useGetResult from "../hooks/useGetResult";
import { useAuth } from "../components/Authentication";
import * as api from "../api";
import * as Styled from "./Workflow.styles";

import UserInfo from "../components/UserInfo";
import WorkflowList from "../components/WorkflowList";

function UserViewGeneric() {
  const params = useParams();

  const [workflows, isLoading, error] = useGetResult(async () => {
    return api.fetchUserPublic(params.username);
  }, [params.username]);

  return (
    <Styled.Container>
      <Styled.Sidebar>
        <UserInfo username={params.username} />
      </Styled.Sidebar>
      <Styled.Content>
        <h1>Latest Workflows</h1>
        {!isLoading && !error && <WorkflowList workflows={workflows.workflows} />}
      </Styled.Content>
    </Styled.Container>
  );
}

function UserViewSelf() {
  const params = useParams();

  const [workflows, isLoading, error] = useGetResult(async () => {
    return api.fetchSelfWorkflows();
  }, [params.username]);

  return (
    <Styled.Container>
      <Styled.Sidebar>
        <UserInfo username={params.username} />
      </Styled.Sidebar>
      <Styled.Content>
        <h1>Latest Workflows</h1>
        {!isLoading && !error && <WorkflowList workflows={workflows.workflows} editMode={true} />}
      </Styled.Content>
    </Styled.Container>
  );
}

function UserView() {
  const auth = useAuth();
  const params = useParams();

  if (auth.isAuthenticated && params.username === auth.user.nickname) {
    return <UserViewSelf />
  }

  return <UserViewGeneric />
}

export default UserView;
