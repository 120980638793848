import _ from "lodash";
import React from "react";

import { Segment } from "../Segment";
import Command from "./Command";

function renderWorkflow(data) {
  return data.map((entry, index) => {
    if (entry.type === "comment") {
      return <h4 key={index}>{entry.comment.slice(2)}</h4>;
    }

    if (entry.type === "command") {
      return <Command key={index} entry={entry} />;
    }

    return <></>;
  });
}

function Workflow({history}) {
  return <Segment>{renderWorkflow(history)}</Segment>;
}

export default Workflow;
