import React from "react";
import { Link } from "react-router-dom";
import useGetResult from "../hooks/useGetResult";
import * as api from "../api";

import WorkflowList from "../components/WorkflowList";
import { Segment } from "../components/Segment";
import Spinner from "../components/Spinner";

import { CommandContainer } from "../components/Workflow/styles";

import * as Styled from "./Landing.styles";

export default function Landing() {
  const [workflows, isLoading] = useGetResult(async () => api.fetchRecentPublic(), []);

  return (
    <Styled.Container>
      <h1>What is Sync.Ninja?</h1>
      <p>Sync.Ninja is a tool to autogenerate a tutorial directly from your terminal!</p>
      <Segment>
        <h2>How to use?</h2>
        <CommandContainer>
          <p>To install run the following command:</p>
          <code>npm install -g syncninja/ninja</code>
          <p>
            <b>
              If you ran into permission problems,{" "}
              <Link to="/DannyBrick/Resolving-EACCES-npm">click here for a guided solution</Link>
            </b>
          </p>
        </CommandContainer>

        <CommandContainer>
          <p>Once installed you will need to login with your GitHub account:</p>
          <code>ninja login</code>
        </CommandContainer>

        <h2>Recording a Session</h2>
        <CommandContainer>
          <p>Use the following command to start recording:</p>
          <code>ninja</code>
        </CommandContainer>
        <CommandContainer>
          <p>To end recording just exit:</p>
          <code>exit (Ctrl+D)</code>
        </CommandContainer>

      </Segment>
      <h1>Latest Public Workflows</h1>
      {isLoading ? <Spinner /> :  <WorkflowList workflows={workflows.workflows} />}
    </Styled.Container>
  );
}
