import styled from 'styled-components';

export const Segment = styled.div`
    background: #FAFBFC;
    border: 1px #E2E4E8 solid;
    border-radius: 4px;
    padding: 1rem;
    color: black;
    margin-top: 1.5rem;
`;
