import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "./App.styles";
import { useQuery } from "../hooks/useQuery";

import AuthenticationProvider from "./Authentication/Provider";

import Header from "./Header";

import Landing from "../views/Landing";
import Workflow from "../views/Workflow";
import User from "../views/User";

function AuthenticatedRoutes() {
  return (
    <Switch>
      <Route path="/:username/:name">
        <Workflow />
      </Route>
      <Route path="/:username">
        <User />
      </Route>
      <Route path="/">
        <Landing />
      </Route>
    </Switch>
  );
}
function NotAuthenticatedRoutes() {
  return (
    <Switch>
      <Route path="/:username/:name">
        <Workflow />
      </Route>
      <Route path="/:username">
        <User />
      </Route>
      <Route path="/">
        <Landing />
      </Route>
    </Switch>
  );
}

const loginSuccessStyle = {
  border: "1px green solid",
  color: "green",
  "margin-top": "1rem",
  padding: "1rem",
  "border-radius": "4px"
};
const loginFailedStyle = {
  border: "1px red solid",
  color: "red",
  "margin-top": "1rem",
  padding: "1rem",
  "border-radius": "4px"
};

function LoginMessage() {
  const query = useQuery();
  const loginStatus = query.get("login");

  if (loginStatus === "success") {
    return (
      <div style={loginSuccessStyle}>
        <i className="fas fa-4x fa-check" />
        <h2>Your terminal has been successfully logged in!</h2>
      </div>
    );
  }

  if (loginStatus === "error") {
    return (
      <div style={loginFailedStyle}>
        <i className="fas fa-4x fa-times" />
        <h2>Well... that's embarrassing... Login have failed...</h2>
      </div>
    );
  }

  return <></>;
}

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <AuthenticationProvider>
      <Router>
        <Header />

        <Container>
          <LoginMessage />
          {!isLoading && isAuthenticated ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />}
        </Container>
      </Router>
    </AuthenticationProvider>
  );
}

export default App;
