import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import * as Styled from "./Header.styles";

export default function Header() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect, logout } = useAuth0();

  return (
    <Styled.Container>
      <Link to="/">
        <i className="fas fa-user-ninja"></i>
      </Link>
      <h1 style={{ flex: 1 }}>
        <Link to="/">
          sync<span>ninja</span>
        </Link>
      </h1>
      {!isLoading && isAuthenticated && (
        <Styled.LoggedIn>
          Logged in as <Link to={`/${user.nickname}`}><b>{user.nickname}</b></Link>
        </Styled.LoggedIn>
      )}
      <div style={{ marginLeft: "1rem" }}>
        {!isLoading && isAuthenticated ? (
          <Styled.LoginWithButton onClick={() => logout({ returnTo: window.location.origin })}>
            <i className="fas fa-sign-out-alt" />
            Logout
          </Styled.LoginWithButton>
        ) : (
          <Styled.LoginWithButton onClick={() => loginWithRedirect()}>
            <i className="fab fa-github" />
            Login with GitHub
          </Styled.LoginWithButton>
        )}
      </div>
    </Styled.Container>
  );
}
