import { useState } from "react";

import * as Styled from "./styles";

function Output({ entry }) {
  const [show, setShow] = useState(false);

  const hasOutput = entry.process_io.length > 1 || (entry.process_io.length === 1 && entry.process_io[0].output !== "");

  if (!hasOutput) {
    return <></>;
  }

  const outputSize = entry.process_io.length;

  if (outputSize === 1) {
    return (
      <>
        <Styled.OutputHeader onClick={() => setShow(!show)}>
          {show ? "[Hide Output]" : "[See Output]"}
        </Styled.OutputHeader>
        {show && <Styled.Output>{entry.process_io[0].output}</Styled.Output>}
      </>
    );

    // Interactive
  } else {
    return (
      <>
        <p>You will see the following output:</p>
        <Styled.Output>{entry.process_io[0].output}</Styled.Output>

        <p>When prompted, fill in the requested information.</p>
        {entry.process_io
          .slice(1)
          .map((io, index) =>
            !io.output ? (
              <Styled.Input id={index}>{io.input === "" ? "<Press Enter>" : io.input}</Styled.Input>
            ) : (
              <Styled.Output id={index}>{io.output}</Styled.Output>
            )
          )}
      </>
    );
  }
}

export default Output;