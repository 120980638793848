import styled from 'styled-components';

export const Container = styled.div`
    width: 1000px;
    margin: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 8rem;
    
    @media only screen and (max-width: 600px) {
        width: auto;
    }

    h1 {
        margin-bottom: 0.5rem;
    }

    h2, h3, h4, h5 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`;
