import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 1rem 2rem;

  color: white;
  background: black;

  & h1,
  h2,
  h3,
  h4 {
    margin: 0;
    white-space: nowrap;

    & a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  & span {
    font-weight: 300;
    display: inline-block;
    margin-left: 4px;
  }

  & > a > i {
    color: white;
    font-size: 2rem;
    margin-right: 0.8rem;
  }

  @media only screen and (max-width: 600px) {
    h1 > a {
      display: none;
    }
  }
`;

export const LoginWithButton = styled.button`
  padding: 0.5rem 1rem;
  background: white;
  color: black;
  border: none;
  font-size: 1rem;
  display: flex;
  align-items: center;

  & i {
      font-size: 1.5rem;
      margin-right: 10px;
  }
`;

export const LoggedIn = styled.div`
  & a {
    color: white;
    text-decoration: none;
    &:hover {
      color: lightblue;
    }
  }
`;