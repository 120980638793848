import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND;

export async function fetchList() {
  return axios.get(`${BASE_URL}/`);
}

export async function fetchRecentPublic() {
  return axios.get(`${BASE_URL}/public/recent`);
}

export async function fetchUserPublic(username) {
  return axios.get(`${BASE_URL}/public/${username}`);
}

export async function fetchSelfWorkflows() {
  return axios.get(`${BASE_URL}/me/workflows`);
}

export async function changeVisibility(username, name, newVisibility) {
  return axios.put(`${BASE_URL}/${username}/${name}`, {visibility: newVisibility});
}

export async function deleteWorkflow(username, name) {
  return axios.delete(`${BASE_URL}/${username}/${name}`);
}

export async function fetchWorkflow(username, name, token) {
  if (token) return axios.get(`${BASE_URL}/${username}/${name}?a=${token}`);
  return axios.get(`${BASE_URL}/${username}/${name}`);
}
