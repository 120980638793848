import styled from 'styled-components';

export const Container = styled.div`
    display: flex;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

export const Sidebar = styled.div`
    margin-top: 1rem;
    margin-right: 2rem;
    min-width: 250px;

    @media only screen and (max-width: 600px) {
        min-width: inherit;
        margin-right: 0;
    }

    & hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        opacity: 0.3;
    }
`;

export const Content = styled.div`
    flex: 1;
    max-width: 700px;

    & h1 {
        margin-bottom: 1rem;
        font-size: 2.2rem;
    }
`;
