import styled from "styled-components";

export const List = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

export const Item = styled.li`
    /* background: #e0eeff; */
    background: #FAFBFC;
    border: 1px #dcdcdc solid;
    border-radius: 4px;
    margin-bottom: 0.4rem;
    padding: 0.6rem 1rem;

    display: flex;
    align-items: center;
`;

export const Title = styled.div`
    margin-right: 1rem;

    & a {
        color: #0263b7;
        text-decoration: none;

        &:hover {
            color: #0088ff;
        }
    }
`;

export const Username = styled.div`
    margin-right: 1rem;
    font-size: 0.85em;

    & a {
        color: #000000a0;
        text-decoration: none;

        &:hover {
            color: #0088ff;
        }
    }
`;

export const Date = styled.div`
    margin-right: 0.6rem;
    font-size: 0.9em;
    color: #000000a0;
`;