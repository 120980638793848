import styled from 'styled-components';

export const CommandContainer = styled.div`
    margin-bottom: 2rem;
`;

export const CopyButton = styled.div`
    float: right;
    opacity: 0.4;
    &:hover {
        opacity: 0.8;
    }
    cursor: pointer;
    user-select: none;
`;

export const Output = styled.pre`
    font-family: 'Source Code Pro', monospace;
    background: black;
    color: white;
    display: block;
    font-size: 12px;
    padding: 0.5rem;
    margin-bottom: 0;
    overflow: auto;
`;

export const Input = styled.pre`
    font-family: 'Source Code Pro', monospace;
    background: #6d6d6d;
    color: white;
    display: block;
    font-size: 12px;
    padding: 0.5rem;
    margin-bottom: 0;
    font-weight: bold;
    overflow: auto;
`;

export const OutputHeader = styled.div`
    text-align: right;
    padding-right: 1rem;
    font-size: 0.75rem;
    margin-top: 2px;
    color: #0f69ad;
    user-select: none;
    cursor: pointer;

    &:hover {
        color: black;
    }
`;
