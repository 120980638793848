import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Segment } from "../components/Segment";
import useGetResult from "../hooks/useGetResult";
import { useQuery } from "../hooks/useQuery";
import * as api from "../api";
import * as Styled from "./Workflow.styles";

import Actions from "../components/Actions";
import UserInfo from "../components/UserInfo";
import WorkflowComponent from "../components/Workflow";

function Workflow() {
  const params = useParams();
  const query = useQuery();
  const secretToken = query.get("a");

  const [result, isLoading, error, reload] = useGetResult(async () => {
    return api.fetchWorkflow(params.username, params.name, secretToken);
  }, [params.username, params.name, secretToken]);

  return (
    <Styled.Container>
      <Styled.Sidebar>
        <UserInfo username={params.username} />
        {result && <Actions workflow={result} onChange={reload} />}
      </Styled.Sidebar>
      <Styled.Content>
        <Helmet>
          <title>{params.name}</title>
        </Helmet>
        <h1>{params.name}</h1>

        {result && <h4>Visibility: {result.visibility}</h4>}
        {result && <h5>Last Modified: {result.last_modified}</h5>}

        {isLoading && <Segment>Loading...</Segment>}
        {error && <Segment>Workflow not found</Segment>}
        {!isLoading && !error && <WorkflowComponent history={result.history} />}
      </Styled.Content>
    </Styled.Container>
  );
}

export default Workflow;
