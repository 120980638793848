import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/css/all.css";
import App from "./components/App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
      <Auth0Provider
        domain="syncninja.eu.auth0.com"
        clientId="vHU0FyOXfEbsL7zZ1WVTzBmrfEYw6aHd"
        redirectUri={window.location.origin}
        audience="cliapi_publish"
        scope="cliapi_publish read:current_user"
      >
        {/* <AuthenticationProvider> */}
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sync.Ninja</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>

        <App />
        {/* </AuthenticationProvider> */}
      </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
